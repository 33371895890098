import React from 'react'
import { graphql } from 'gatsby'

import Layout from '../components/common/layout'
import SEO from '../components/common/seo'

const HomePage = ({ data }) => {

	const pageDetails = data.homePage.edges[0].node

	return (
		<Layout isHome={true}>
			<SEO
				title={pageDetails.title}
				description={pageDetails.description}
				img={pageDetails.shareImage.file.url}
			/>
		</Layout>
	)
}

export default HomePage

export const homePageQuery = graphql`
	query HomeQuery{
		homePage:allContentfulPageDetails(filter: {title: {eq: "Home"}}) {
			edges {
				node {
					title
					description
					shareImage {
						file {
							url
						}
					}
				}
			}
		}
	}
`
